import React from 'react';
import PropTypes from 'prop-types';

import bytesToSize from 'utils/bytesToSize';
import getMimeType from 'utils/getMimeType';
import { apiUrl } from 'utils/http';

import { ReactComponent as DownloadArrow } from 'assets/icons/download-arrow.svg';
import MediaPlayButton from '../../MediaPlayButton';
import StyledItem from '../styles/StyledItem';
import StyledCheckbox from '../styles/StyledCheckbox';

function Item({ item, selectionMode, handleClick, checked }) {
  let checkboxDiv = null;

  if (selectionMode) {
    checkboxDiv = <StyledCheckbox checked={checked} color="blue" />;
  }

  const isImage = getMimeType(item.content_type) === 'image';
  const isAudio = getMimeType(item.content_type) === 'audio';
  const backgroundUrl =
    item.formats && item.formats.user_banner ? `${apiUrl}${item.formats.user_banner.url}` : null;

  const mediaFileUrl = process.env.REACT_APP_API_URL;

  return (
    <StyledItem
      hasBackground={isImage}
      style={{
        backgroundImage: isImage && backgroundUrl ? `url(${backgroundUrl})` : null,
      }}
      onClick={() => handleClick(item.id)}
    >
      <div className="item_inside">
        <DownloadArrow
          onClick={e => {
            window.open(mediaFileUrl + item.formats.reference.url, '_blank').focus();
          }}
        />
        {isAudio && <MediaPlayButton audioFile={item} />}
        {isImage && (
          <div>
            {item.width}
            <span> x </span>
            {item.height}
          </div>
        )}
        <p>{bytesToSize(item.size)}</p>
        <p>{item.name}</p>
      </div>
      {checkboxDiv}
    </StyledItem>
  );
}

Item.defaultProps = {
  selectionMode: false,
};

Item.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  selectionMode: PropTypes.bool,
  handleClick: PropTypes.instanceOf(Function).isRequired,
  checked: PropTypes.bool.isRequired,
};

export default Item;
