import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { ReactComponent as TipplyLogo } from 'assets/icons/tipply-logo.svg';
import { ReactComponent as TipplyLogoWhite } from 'assets/icons/tipply-logo-white.svg';
import { useSelector } from 'react-redux';

const wrapperCss = css`
  display: block;
  margin: 0 85px 0 0;

  svg {
    display: block;
    width: 130px;
    height: 46px;
    path {
      fill: var(--text-color);
    }
    ellipse {
      fill: var(--brand-green);
    }
  }
`;

const LinkWrapper = styled(Link)`
  ${wrapperCss};
`;

const NoLinkWrapper = styled.div`
  ${wrapperCss};
`;

const Logo = ({ noLink }) => {
  const { info: userDataInfo } = useSelector(state => state.userData);
  const isConfigPage = window.location.href.match('konfigurator');
  const isDefaultTheme = userDataInfo.color_theme === 'default';

  if (noLink) {
    return (
      <NoLinkWrapper>
        {isDefaultTheme && !isConfigPage ? (
          <TipplyLogo title="Tipply.pl" />
        ) : (
          <TipplyLogoWhite title="Tipply.pl" />
        )}
      </NoLinkWrapper>
    );
  }
  return (
    <LinkWrapper to="/">
      {isDefaultTheme && !isConfigPage ? (
        <TipplyLogo title="Tipply.pl" />
      ) : (
        <TipplyLogoWhite title="Tipply.pl" />
      )}
    </LinkWrapper>
  );
};

Logo.propTypes = {
  noLink: PropTypes.bool,
};

Logo.defaultProps = {
  noLink: false,
};

export default Logo;
