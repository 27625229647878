import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import FloatingMenu from 'components/FloatingMenu';
import Button from 'components/Button';
import OptionsPopup from 'styles/OptionsPopup';
import SingleColor from '../../../components/ColorPicker/components/SingleColor';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-3.svg';

const ColorPickerButton = styled.div`
  width: 270px;
  height: 58px;
  cursor: pointer;
  background: var(--bg-color-white-gray);
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .icon {
    width: 33px;
    height: 33px;
    border-radius: 17px;
    background: #2cd283;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
  }
  .label1 {
    font-family: 'Sora';
    font-style: normal;
    font-weight: 800;
    font-size: 10px;
    line-height: 13px;
    color: #606060;
  }
  .label2 {
    font-family: 'Sora';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    color: var(--text-color);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;

const PanelButtonsWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const ColorPickerBoxWrapper = styled.div`
  & > div,
  & > div > div {
    min-width: auto;
  }
`;

export const ColorPickerBox = ({ label, value, onChange }) => {
  const rootRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [localValue, setLocalValue] = useState(value);

  const { color } = value;

  const handleClick = () => {
    setIsOpen(prevState => !prevState);
  };

  const handleConfirm = () => {
    onChange(localValue.color);
    setIsOpen(false);
  };

  return (
    <ColorPickerBoxWrapper style={{ position: 'relative' }} ref={rootRef}>
      <ColorPickerButton onClick={handleClick}>
        <div>
          <div className="icon">
            <EditIcon />
          </div>
        </div>
        <div>
          <div className="label1">KOLOR TWOJEJ STRONY WPŁAT</div>
          <div className="label2">Kliknij i zmień motyw</div>
        </div>
      </ColorPickerButton>

      <FloatingMenu
        visible={isOpen}
        position="right"
        onClose={() => {
          setIsOpen(false);
        }}
        parent={rootRef.current || document.body}
      >
        <OptionsPopup style={{ padding: 15 }}>
          {isOpen && (
            <SingleColor
              pickerPosition="static"
              color={color}
              onChange={newColor => {
                setLocalValue({
                  color: newColor,
                });
              }}
            />
          )}
          <PanelButtonsWrapper>
            <Button background="green" onClick={handleConfirm}>
              Zatwierdź
            </Button>
          </PanelButtonsWrapper>
        </OptionsPopup>
      </FloatingMenu>
    </ColorPickerBoxWrapper>
  );
};

ColorPickerBox.propTypes = {
  label: PropTypes.node.isRequired,
  value: PropTypes.shape({
    color: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.instanceOf(Function).isRequired,
};
