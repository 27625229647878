import React, { useState } from 'react';
import PropTypes from 'prop-types';
import keycode from 'keycode';

import serverAlert from 'store/utils/serverAlert';

import Text from 'components/Text';
import TextInput from 'components/TextInput';

import Flexbox from 'components/Flexbox';
import WhiteBox from 'styles/WhiteBox';

import { Consumer } from '../Context';

const TipsUrl = ({ userDataProfile }) => {
  const [description, setDescription] = useState(userDataProfile.description || '');
  const [loading, setLoading] = useState(false);

  return (
    <Consumer>
      {({ updateTipsPageSettings }) => (
        <WhiteBox style={{ padding: '10px 20px' }}>
          <Flexbox align="center" justify="between">
            <Text size={16} margin="0 25px 0 0" style={{ whiteSpace: 'nowrap' }}>
              Ustaw opis do swojej strony zamówień:
            </Text>
            <TextInput
              value={description}
              onChange={({ target }) => setDescription(target.value)}
              disabled={loading}
              loading={loading}
              onBlur={async () => {
                if (userDataProfile.description === description) return;

                setLoading(true);

                try {
                  await updateTipsPageSettings({ description });
                } catch (error) {
                  serverAlert(error.response.data.errors.children.description.errors.join(', '));
                }

                setLoading(false);
              }}
              onKeyPress={event => {
                const keyCodeType = keycode(event);

                if (keyCodeType === 'enter') {
                  event.target.blur();
                }
              }}
              maxLength={300}
            />
          </Flexbox>
        </WhiteBox>
      )}
    </Consumer>
  );
};

TipsUrl.propTypes = {
  userDataProfile: PropTypes.instanceOf(Object).isRequired,
};

export default TipsUrl;
