import React, { useState } from 'react';
import serverAlert from 'store/utils/serverAlert';
import WhiteBox from 'styles/WhiteBox';
import PropTypes from 'prop-types';
import { Consumer } from '../../Context';
import { ColorPickerBox } from '../../../Configurators/components/ColorPickerBox';
import SocialMedia from '../SocialMedia';

const Theme = ({ color }) => {
  const [themeColor, setThemeColor] = useState(color);

  return (
    <Consumer>
      {({ updateTipsPageSettings, updateSocialMediaData, socialMediaData, userDataProfile }) => (
        <WhiteBox style={{ padding: '40px 25px', marginTop: 15 }}>
          <ColorPickerBox
            value={{
              color: themeColor,
            }}
            onChange={async newColor => {
              setThemeColor(newColor);
              try {
                await updateTipsPageSettings({ themeColor: newColor });
              } catch (error) {
                serverAlert('Wystąpił błąd', 'error');
              }
            }}
          />
          <SocialMedia
            socialMediaData={socialMediaData}
            updateSocialMediaData={updateSocialMediaData}
            userDataProfile={userDataProfile}
          />
        </WhiteBox>
      )}
    </Consumer>
  );
};

Theme.propTypes = {
  color: PropTypes.string.isRequired,
};

export default Theme;
