import React, { Component } from 'react';
import PropTypes from 'prop-types';

import WhiteBox from 'styles/WhiteBox';

import Grid from 'components/Grid';
import Text from 'components/Text';
import Container from './components/Container';
import TipsUrl from './components/TipsUrl';
import SetBanner from './components/SetBanner';
import SetDescription from './components/SetDescription';
import SetEmotes from './components/SetEmotes';
import VoiceMessages from './components/VoiceMessages';
import PaymentMethods from './components/PaymentMethods';
import ImagePanel from './components/ImagePanel';
import Theme from './components/Theme';
import Avatar from './components/Avatar';

import { Provider } from './Context';
import Flexbox from '../../components/Flexbox';
import MethodStateSwitch from './components/PaymentMethods/components/MethodStateSwitch';

export default class TipsPageSettings extends Component {
  static propTypes = {
    userDataProfile: PropTypes.instanceOf(Object).isRequired,
    socialMediaData: PropTypes.instanceOf(Object).isRequired,
    fetchProfileDataIfNeeded: PropTypes.instanceOf(Function).isRequired,
    forceConfigFetch: PropTypes.instanceOf(Function).isRequired,
    syncGoogleAvatar: PropTypes.instanceOf(Function).isRequired,
    updateUserMedia: PropTypes.instanceOf(Function).isRequired,
    deleteUserMedia: PropTypes.instanceOf(Function).isRequired,
    updateTipsPageSettings: PropTypes.instanceOf(Function).isRequired,
    updateSocialMediaData: PropTypes.instanceOf(Function).isRequired,
    userDataInfo: PropTypes.instanceOf(Object).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showRankingAndMessages: props.userDataProfile.show_ranking_and_messages,
    };
  }

  componentDidMount() {
    const { fetchProfileDataIfNeeded, forceConfigFetch } = this.props;

    fetchProfileDataIfNeeded();
    forceConfigFetch();

    document.title = 'Tipply | Ustawienia strony zamówień';
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { userDataProfile } = this.props;

    if (
      prevProps.userDataProfile.show_ranking_and_messages !==
      userDataProfile.show_ranking_and_messages
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ showRankingAndMessages: userDataProfile.show_ranking_and_messages });
    }
  }

  render() {
    const {
      syncGoogleAvatar,
      userDataProfile,
      socialMediaData,
      updateTipsPageSettings,
      updateSocialMediaData,
      updateUserMedia,
      deleteUserMedia,
      userDataInfo,
    } = this.props;

    const { showRankingAndMessages } = this.state;

    if (Object.keys(userDataProfile).length < 1) return '';

    return (
      <Provider
        value={{
          userDataProfile,
          socialMediaData,
          updateTipsPageSettings,
          updateSocialMediaData,
          deleteUserMedia,
        }}
      >
        <Container>
          <WhiteBox style={{ padding: '35px 30px' }}>
            <Flexbox direction="row" align="center">
              <div style={{ minWidth: '700px' }}>
                <h3 style={{ fontSize: 25, fontWeight: 700, margin: '0 0 10px' }}>
                  Ustawienia strony zamówień
                </h3>
                <Text noMargin>
                  W tym miejscu możesz skonfigurować stronę, na której Twoi widzowie wysyłają
                  wiadomości.
                  <br />
                  Pamiętaj aby dokonać wyboru dostępnych metod, dodać banner, oraz motyw strony.
                </Text>
              </div>
              <Flexbox direction="row" justify="center" align="center" gap="20px">
                <MethodStateSwitch
                  enabled={showRankingAndMessages}
                  onChange={() => {
                    this.setState({ showRankingAndMessages: !showRankingAndMessages });
                    updateTipsPageSettings(
                      {
                        showRankingAndMessages: !showRankingAndMessages,
                      },
                      false,
                    ).catch(() => {
                      this.setState({ showRankingAndMessages });
                    });
                  }}
                />
                <div>
                  Wyświetlaj ranking wpłat oraz listę ostatnich wiadomości na mojej stronie wpłat
                </div>
              </Flexbox>
            </Flexbox>
          </WhiteBox>

          <TipsUrl userDataProfile={userDataProfile} />

          <SetBanner
            mediumType="banner"
            onSelectedItem={updateUserMedia}
            onDeleteItem={deleteUserMedia}
          />

          <Grid columns="320px 1fr">
            <div>
              <Avatar
                syncGoogleAvatar={syncGoogleAvatar}
                mediumType="avatar"
                onSelectedItem={updateUserMedia}
              />
              <Theme color={userDataProfile.theme_color} />
            </div>
            <div>
              <SetDescription userDataProfile={userDataProfile} />
              {/* Panel dla avatara */}
              <ImagePanel
                mediumType="avatar"
                onSelectedItem={updateUserMedia}
                onDeleteItem={deleteUserMedia}
                style={{ margin: '15px 0', paddingLeft: 175 }}
              />
              {userDataInfo.payments_disabled && (
                <WhiteBox style={{ padding: '10px 20px', margin: '10px 0 10px 0' }}>
                  <Flexbox align="center" justify="between">
                    <Text
                      size={14}
                      margin="0 25px 0 0"
                      style={{ color: 'red', whiteSpace: 'nowrap' }}
                    >
                      Wyłączono możliwość płatności na Twoim profilu. Skontaktuj się z nami.
                    </Text>
                  </Flexbox>
                </WhiteBox>
              )}
              {userDataInfo.fraud && (
                <WhiteBox style={{ padding: '10px 20px', margin: '10px 0 10px 0' }}>
                  <Flexbox align="center" justify="between">
                    <Text
                      size={14}
                      margin="0 25px 0 0"
                      style={{ color: 'red', whiteSpace: 'nowrap' }}
                    >
                      Zablokowano możliwość płatności na Twoim profilu. Skontaktuj się z nami.
                    </Text>
                  </Flexbox>
                </WhiteBox>
              )}
              {userDataInfo.missing_personal_data && (
                <WhiteBox style={{ padding: '10px 20px', margin: '10px 0 10px 0' }}>
                  <Flexbox align="center" justify="between">
                    <Text size={14} margin="0 25px 0 0" style={{ color: 'red' }}>
                      Metody płatności zostaną automatycznie uruchomione w Twoim profilu po
                      wprowadzeniu i zaakceptowaniu danych osobowych przez administratora serwisu.
                      Trwa to zazwyczaj do 24 godziny od momentu ich przesłania. Informację o
                      zaakceptowaniu otrzymasz mailowo.
                    </Text>
                  </Flexbox>
                </WhiteBox>
              )}
              <PaymentMethods />
            </div>
          </Grid>
          <SetEmotes userDataProfile={userDataProfile} />
          <VoiceMessages />
        </Container>
      </Provider>
    );
  }
}
