import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ReactComponent as FacebookIcon } from '../../../../assets/icons/social-media/facebook.svg';
import { ReactComponent as DiscordIcon } from '../../../../assets/icons/social-media/discord.svg';
import { ReactComponent as InstagramIcon } from '../../../../assets/icons/social-media/instagram.svg';
import { ReactComponent as KickIcon } from '../../../../assets/icons/social-media/kick.svg';
import { ReactComponent as TiktokIcon } from '../../../../assets/icons/social-media/tiktok.svg';
import { ReactComponent as TwitchIcon } from '../../../../assets/icons/social-media/twitch.svg';
import { ReactComponent as XIcon } from '../../../../assets/icons/social-media/x.svg';
import { ReactComponent as YoutubeIcon } from '../../../../assets/icons/social-media/youtube.svg';
import FloatingInputCustomButton from '../../../Configurators/components/FloatingInputCustomButton';
import Input from '../../../../styles/Input';

const SocialMediaWrapper = styled.div`
  .label {
    font-family: 'Sora';
    font-style: normal;
    font-weight: 800;
    font-size: 10px;
    line-height: 13px;
    text-align: center;
  }
  .label1 {
    color: #adadad;
  }
  .label2 {
    color: #606060;
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .divider {
    flex: 1;
    border-bottom: 1px solid #474747;
  }
  .middle {
    padding: 0px 15px;
  }
  margin: 15px 0px;
`;
const SocialMediaIconsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns per row */
  gap: 10px; /* Space between icons */
  justify-content: center;
  align-items: center;
  max-width: 210px; /* Adjust based on layout */
  margin: 15px 30px;
`;

const SocialMediaIcon = styled.div`
  width: 45px;
  height: 45px;
  border: 2px solid #474747;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover,
  &.active {
    background: rgba(71, 71, 71, 0.5);
    cursor: pointer;
    fill: var(--text-white-gray);
  }
  fill: #474747;
`;

const socialMediaDefinitions = [
  {
    type: 'facebook',
    icon: FacebookIcon,
    placeholder: 'https://facebook.com/tipplypl',
  },
  {
    type: 'discord',
    icon: DiscordIcon,
    placeholder: 'Dodaj zaproszenie do swojego Discorda',
  },
  {
    type: 'instagram',
    icon: InstagramIcon,
    placeholder: 'https://instagram.com/tipplypl',
  },
  {
    type: 'kick',
    icon: KickIcon,
    placeholder: 'https://kick.com/tipplypl',
  },
  {
    type: 'tiktok',
    icon: TiktokIcon,
    placeholder: 'https://tiktok.com/@tipplypl',
  },
  {
    type: 'twitch',
    icon: TwitchIcon,
    placeholder: 'https://twitch.tv/tipplypl',
  },
  {
    type: 'x',
    icon: XIcon,
    placeholder: 'https://x.com/tipplypl',
  },
  {
    type: 'youtube',
    icon: YoutubeIcon,
    placeholder: 'https://youtube.com/tipplypl',
  },
];

const SocialMedia = ({ socialMediaData, updateSocialMediaData, userDataProfile }) => {
  const [socialMediaDataUnique, setSocialMediaDataUnique] = useState([
    ...new Map(socialMediaData.map(item => [item.type, item])).values(),
  ]);
  return (
    <SocialMediaWrapper>
      <div className="row">
        <div className="divider" />
        <div className="middle">
          <div className="label label1">SOCIAL MEDIA</div>
          <div className="label label2">{`PODŁĄCZONO ${socialMediaDataUnique.length} z 8`}</div>
        </div>
        <div className="divider" />
      </div>
      <SocialMediaIconsWrapper>
        {socialMediaDefinitions.map(item => {
          const socialMediaItem = socialMediaDataUnique.find(sm => sm.type === item.type);
          return (
            <FloatingInputCustomButton
              buttonComponent={
                <SocialMediaIcon className={socialMediaItem ? 'active' : ''}>
                  <item.icon />
                </SocialMediaIcon>
              }
              popupComponent={
                <Input
                  placeholderOpacity={0.3}
                  defaultValue={socialMediaItem && socialMediaItem.link}
                  placeholder={item.placeholder}
                  onBlur={e => {
                    setSocialMediaDataUnique(
                      socialMediaDataUnique
                        .filter(sm => sm.type !== item.type)
                        .concat({ type: item.type, link: e.target.value }),
                    );
                    updateSocialMediaData({
                      id: socialMediaItem ? socialMediaItem.id : undefined,
                      type: item.type,
                      link: e.target.value,
                      profileLink: userDataProfile.link,
                    });
                  }}
                />
              }
            />
          );
        })}
      </SocialMediaIconsWrapper>
    </SocialMediaWrapper>
  );
};

SocialMedia.propTypes = {
  socialMediaData: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateSocialMediaData: PropTypes.func.isRequired,
  userDataProfile: PropTypes.instanceOf(Object).isRequired,
};

export default SocialMedia;
