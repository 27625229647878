import { connect } from 'react-redux';

import {
  syncGoogleAvatar,
  updateTipsPageSettings,
  updateSocialMediaData,
  updateUserMedia,
  deleteUserMedia,
} from 'store/actions/userData';
import { forceConfigFetch } from 'store/actions/configuratorsConfigs';

import TipsPageSettings from './TipsPageSettings';

const mapStateToProps = ({ userData }) => ({
  userDataProfile: userData.profile,
  socialMediaData: userData.socialMedia,
  userDataInfo: userData.info,
});
const mapDispatchToProps = dispatch => ({
  fetchProfileDataIfNeeded() {},
  forceConfigFetch: () => dispatch(forceConfigFetch()),
  syncGoogleAvatar: () => dispatch(syncGoogleAvatar()),
  updateUserMedia: (mediumType, mediumObject) =>
    dispatch(updateUserMedia(mediumType, mediumObject)),
  deleteUserMedia: mediumType => dispatch(deleteUserMedia(mediumType)),
  updateTipsPageSettings: (config, displaySuccessMessage) =>
    dispatch(updateTipsPageSettings(config, displaySuccessMessage)),
  updateSocialMediaData: data => dispatch(updateSocialMediaData(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TipsPageSettings);
