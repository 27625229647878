import styled from 'styled-components';

const Description = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: var(--text-color);
  a {
    color: var(--text-color);
  }

  margin: 0 0 25px;
`;

export default Description;
