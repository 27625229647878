import React from 'react';
import { alert } from 'components/Alert';

export default (message, type = 'error', confirmButtonText) => {
  alert.open({
    config: {
      type,
      title: type === 'error' ? 'Coś się pokićkało' : 'Sukces',
      description: <span dangerouslySetInnerHTML={{ __html: message }} />,
      confirmButtonText: confirmButtonText || (type === 'error' ? 'Innym razem' : 'Dzięki wielkie'),
    },
  });
};
